import React from 'react'
import DashboardComp from '../../Components/dashboard/dashboard'
import BaseLayout from '../../Components/BaseLayout'

const DashboardPage = () => {
    return (
        <BaseLayout>
            <DashboardComp />
        </BaseLayout>
    )
}

export default DashboardPage
