import React from 'react'
import CreateAppointment from '../../Components/tabsContentAppointments/CreateAppointment'
import Baselayout from '../../Components/BaseLayout';

const CreateAppointmentPage = () => {
    return (
        <Baselayout>
            <CreateAppointment />
        </Baselayout>
    ) 
}

export default CreateAppointmentPage
