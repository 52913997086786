import React from 'react'
import Returningpatient from '../../Components/tabsContentReturningPatient/ReturningPatient'

function ReturningPatient() {

  return (
    <> 
    <Returningpatient />
    </>
  )

}

export default ReturningPatient