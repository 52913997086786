import React from 'react'
import Patients from '../../Components/patients/Patients'

const PatientsPage = () => {
    return (
        <div>
            <Patients />
        </div>
    )
}

export default PatientsPage;
