import React from 'react'
import VerifyOtpComp from '../../Components/verifyOtp/verifyOtp';

const verifyOtp = () => {
  return (
    <div>    
        <VerifyOtpComp />
    </div>
  )
}

export default verifyOtp