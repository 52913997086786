import React from 'react'
import AuthComp from '../../Components/auth/Auth'

const DoctorLogin = () => {
    return (
        <>
            <AuthComp />
        </>
    )
}

export default DoctorLogin;
